.addSeries {
  flex: 5;
}
.addSeriesContainer {
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.addSeriesTitle {
  color: rgba(233,136,45,0.9)
}
.addSeriesForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.addSeriesItem {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.addSeriesItem > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}

.addSeriesItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.addSeriesSelect{
    height: 40px;
    border-radius: 5px;
}

.addSeriesButton{
    width: 200px;
    border: none;
    background-color: rgba(233,136,45,0.9);
    color: white;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}