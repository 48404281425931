.settings {
  flex: 5;
  padding: 20px;
}

.settingsTitleContainer {
  color: rgba(233,136,45,0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .settingsShowRight {
      display: flex;
      flex-direction: column;
  }    
  .settingsShow {
      display: flex;
      flex-direction: column;
  }
  .settingsUpdateForm{
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .settingsShowRight {
      display: flex;
      flex-direction: column;
  }    
.settingsShow {
      display: flex;
      flex-direction: column;
  }    
  .settingsUpdateForm{
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 0px) {
  .settingsShowRight {
      display: flex;
      flex-direction: column;
  }    
  .settingsShow {
      display: flex;
      flex-direction: column;
  }    
  .settingsUpdateForm{
    display: flex;
    flex-direction: column;
  }
}

.settingsContainer {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.settingsShow {
  display: flex;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.settingsUpdate {
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.settingsShowLeft {
  flex: 1;
  display: flex;
  align-items: center;
}

.settingsShowImg {
  width: 160px;
  height: 120px;
  border-radius: 25%;
  object-fit: cover;
}

.settingsShowLeftTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.settingsShowUsername {
  font-weight: 600;
}

.settingsShowUserTitle {
  font-weight: 300;
}

.settingsShowRight{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.settingsShowRightChannel {
  flex: 1;
  margin-right: 20px;
}
.settingsShowRightContact {
  flex: 1;
  margin-right: 20px;
}
.settingsShowRightSocial {
  flex: 1;
  margin-right: 30px;
}
.settingsShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.settingsShowInfo{
    display: flex;
    align-items: center;
    margin: 5px 0px;
    color: #444;
}

.settingsShowIcon{
    font-size: 16px !important;
}

.settingsShowInfoTitle{
    margin-left: 10px;
}

.settingsUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}

.settingsUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.settingsUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.settingsUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}
.settingsUpdateInput{
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}
.settingsUpdateInputTextArea{
    border: none;
    border-bottom: 1px solid gray;
}


.settingsUpdateLeft{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.settingsUpdateRight{
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settingsUploadImagesSection {
  display: flex;
  justify-content: space-between;
}
.settingsUpdateUpload{
    display: flex;
    align-items: center;
}
.settingsUpdateUploadImgLabel {
  display: flex;
  flex-direction: column;
}
.settingsUpdateImgLogo{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}
.settingsUpdateImgFlash{
    width: 180px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}
.settingsUpdateIcon{
    cursor: pointer;
}
.settingsUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: rgba(233,136,45,0.9);
    color: white;
    font-weight: 600;
}