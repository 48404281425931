.topten {
  flex: 1;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  padding: 20px;
}

.toptenTitle {
  font-size: 22px;
  font-weight: 600;
}

.toptenTable{
  width: 100%;
  border-spacing: 20px;
}