.chartPie {
  margin: 20px;
  padding: 10px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.chartPieTitle {
  margin-bottom: 20px;
}
