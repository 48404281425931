.videoList{
    flex: 5;
    padding: 20px;
    height : 700px;
}

.videoListHeader {
    color: rgba(233,136,45,0.9);
    margin-bottom: 20px;
}

.videoListItem{
    display: flex;
    align-items: center;
}

.videoListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.videoListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.videoListDelete{
    color: red;
    cursor: pointer;
}

.videoTitleContainer {
    color: rgba(233,136,45,0.9);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.videoRefButton {
    width: 120px;
    border: none;
    padding: 5px;
    background-color: rgba(233,136,45,0.9);
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}
  