.videoSingle {
  flex: 5;
  padding: 20px;
}

.videoSingleTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videoSingleTitle {
  color: rgba(233,136,45,0.9)
}
/* Button hidden as of now
.videoSingleAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
} */



.videoSingleTop {
    flex: 1;
    display: grid;
    /* display: flex; */
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-content: space-between;
    /* margin-bottom: 5px; */
}
.videoSingleTopLeft{
  max-width: 400px;
  /* flex: 1; */
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);

}

.videoSingleTopMiddle{
  /* flex: 1; */
  max-width: 400px !important;
  min-width: 200px;
  /* max-height: 400px; */
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.videoSingleTopRight {
  /* flex: 1; */
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

@media (max-width: 640px) {
    .videoSingleTop {
        display: grid;
        grid-template-columns: 1fr;
    }
    /* .videoSingleInfoItem {
      display: flex;
      flex-direction: column;
    } */
    .videoSingleBottom {
      display: flex;
      flex-direction: column;
    }
    .videoSingleBottomURL {
      display: flex;
      flex-direction: column;
    }
}
@media (max-width: 500px) {
    .videoSingleTop {
        display: grid;
        grid-template-columns: 1fr;
    }    
    .videoSingleInfoItem {
      display: flex;
      flex-direction: column;
    }
    .videoSingleBottom {
      display: flex;
      flex-direction: column;
    }
    .videoSingleBottomURL {
      display: flex;
      flex-direction: column;
    }
}


.videoSingleCenterImg{
  max-width:100%;
  max-height:100%;
  border-radius: 10px;
  object-fit: cover;
}

.videoSingleInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.videoSingleInfoTop {
  display: flex;
  align-items: center;
}

.videoSingleName {
  font-weight: 600;
}

.videoSingleInfoBottom {
  margin-top: 10px;
}

.videoSingleInfoItem {
  display: flex;
  justify-content: space-between;
}
.videoSingleInfoKey {
  flex: 1;
}
.videoSingleInfoValue {
  flex: 3;
  font-weight: 300;
}

.videoSingleBottom {
  display: flex;
}

.videoSingleBottomNonEdit{
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  padding: 20px;
  margin: 20px;
  color: gray;
  flex: 3;
  line-height: 2rem;
}

.videoSingleNonEditItem {
  display: flex;
  justify-content: space-between;
}
.videoSingleNonEditKey {
  flex: 1;
}
.videoSingleNonEditValue {
  flex: 1;
  font-weight: 300;
}

.videoSingleBottomEditable {
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  padding: 20px;
  margin: 20px;
  flex: 7;
}

.videoSingleBottomURL {
  display: flex;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.videoSingleURLLeft,
.videoSingleURLRight {
  flex: 1;
}
