.featured {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 30px;
}

.featuredRow {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
}
@media (max-width: 640px) {
    .featuredRow {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }    
}
@media (max-width: 480px) {
    .featuredRow {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }    
}
@media (max-width: 0px) {
    .featuredRow {
        display: grid;
        grid-template-columns: 1fr;
    }    
}


.featuredItemData1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}
.featuredItemDataTop, .featuredItemDataBottom {
    flex: 1;
    display: flex;
}
.featuredItemDataLeft {
    flex: 1;
}

.featuredItemDataRight {
    flex: 1;
    margin: 5px 5px 20px 5px;
    padding: 20px 10px;
    font-size: 25px;
    border: 1px solid #8b4402;
    border-radius: 30%;
    align-self: stretch;
    /* flex-grow: 1; */
    background-color: #fdf8f3;
}
.featuredItemData {
  flex: 1;
  margin: 0px 20px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}
.featuredItemChart {
  flex: 1;
}

.featuredTitle{
    font-size: 20px;
}

.featuredCardContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featuredCard{
    font-size: 30px;
    font-weight: 600;
}

.featuredCardRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative{
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: gray;
}

.featuredGrad {
  background-image: linear-gradient(orange, yellow);
}