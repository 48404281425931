
.featuredItemData {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}
.featuredItemDataTop, .featuredItemDataBottom {
    flex: 1;
    display: flex;
}
.featuredItemDataLeft {
    flex: 1;
}

.featuredItemDataRight {
    flex: 1;
    margin: 20px;
    padding: 20px;
    font-size: 20px;
    border: 1px solid #8b4402;
    border-radius: 30%;
    align-self: stretch;
    /* flex-grow: 1; */
    background-color: #fdf8f3;
}
.featuredItemData {
  flex: 1;
  margin: 0px 20px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
  -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}
.featuredItemChart {
  flex: 1;
}

.featuredTitle{
    font-size: 20px;
}

.featuredCardContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featuredCard{
    font-size: 30px;
    font-weight: 600;
}

.featuredCardRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
}

.featuredIcon.positive{
    color: green;
}
.featuredIcon.negative{
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: gray;
}

.featuredGrad {
  background-image: linear-gradient(orange, yellow);
}