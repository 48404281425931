.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
}
/* position: fixed;
width: 50px;
transition: 0.5s;
overflow: hidden;

.sidebar:hover {
  width: 300px;
} */

.sidebarLogo {
  height: 56px;
  width: 120px;
}
.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 16px;
  color: rgb(158, 152, 152);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(233, 136, 45);
}
  
.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}
