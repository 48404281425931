.home{
    flex: 5;
}

.homeWidgets{
    display: flex;
    margin: 20px;
}

.homeChart {
    flex: 2;
    padding: 20px;
    margin: 20px;
    box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
    -webkit-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
    -moz-box-shadow: 0px 0px 20px -9px rgba(233,136,45,0.9);
}

.homePie {
    flex: 1;
}