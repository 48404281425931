.userList {
  flex: 5;
  margin: 20px;
  height : 500px;
}

.userTitleContainer {
  color: rgba(233,136,45,0.9);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAddButton {
  width: 120px;
  border: none;
  padding: 5px;
  background-color: rgba(233,136,45,0.9);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}